import React, { useContext } from "react"
import styled from "styled-components"
import { Title, Paragraph, content } from "../utils"
//import LanguageContext from "../contexts/LanguageContext"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Technologies = () => {
  //const { lang } = useContext(LanguageContext)

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "tech" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  return (
    <TechWrapper id="technologies">
      <Title titleText={content.Technologies.title.fr} />
      <Paragraph>
        {content.Technologies.para1.partOne.fr}
        <a
          href={content.Technologies.para1.links.jamstack.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.Technologies.para1.links.jamstack.text}
        </a>
        {`, `}
        <a
          href={content.Technologies.para1.links.serverless.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.Technologies.para1.links.serverless.text}
        </a>
        {`, `}
        <a
          href={content.Technologies.para1.links.static.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.Technologies.para1.links.static.text.fr}
        </a>
        {` & `}
        <a
          href={content.Technologies.para1.links.headless.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.Technologies.para1.links.headless.text.fr}
        </a>
        {content.Technologies.para1.partTwo.fr}
      </Paragraph>
      <Paragraph>{content.Technologies.para2.fr}</Paragraph>
      <Paragraph>
        {content.Technologies.para3.partOne.fr}{" "}
        <a
          href={content.Technologies.para3.links.react.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.Technologies.para3.links.react.text}
        </a>
        {content.Technologies.para3.partTwo.fr}
        <a
          href={content.Technologies.para3.links.gatsby.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.Technologies.para3.links.gatsby.text}
        </a>
        {content.Technologies.para3.partThree.fr}
      </Paragraph>
      <ImageWrapper>
        {data.allFile.edges.map((item, i) => {
          return (
            <div className="tech-image" key={i}>
              <Img
                fluid={item.node.childImageSharp.fluid}
                alt={`${item.node.name} logo`}
              />
            </div>
          )
        })}
      </ImageWrapper>
    </TechWrapper>
  )
}

const TechWrapper = styled.div`
  padding: 3rem 0;
`

const ImageWrapper = styled.div`
  margin-top: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .tech-image {
    padding: 1rem 3.5rem;
    @media screen and (max-width: 768px) {
      padding: 0.5rem 1.5rem;
    }
  }
`

export default Technologies
