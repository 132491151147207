import React, { useContext } from "react"
import styled from "styled-components"
import { Title, styles } from "../utils"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
//import LanguageContext from "../contexts/LanguageContext"

const Portfolio = () => {
  //const { lang } = useContext(LanguageContext)

  const data = useStaticQuery(graphql`
    {
      allContentfulPortfolio {
        edges {
          node {
            title
            slug
            thumbnail {
              fluid {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  return (
    <PortfolioWrapper id="portfolio">
      <Title titleText="Portfolio" />
      <ItemsWrapper>
        {data.allContentfulPortfolio.edges.map(({ node }, i) => {
          //if (node.node_locale.substring(0, 2) === lang) {
          return (
            <ItemWrapper key={i}>
              <Link to={`/portfolio/${node.slug}`}>
                <Img fluid={node.thumbnail.fluid} />
              </Link>
              <h2>{node.title}</h2>
            </ItemWrapper>
          )
          //}
        })}
      </ItemsWrapper>
    </PortfolioWrapper>
  )
}

const PortfolioWrapper = styled.div`
  padding: 3rem 0;
`

const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`

const ItemWrapper = styled.div`
  position: relative;
  width: 45%;
  display: block;
  h2 {
    margin-top: 1rem;
    text-align: center;
  }
  @media screen and (max-width: 560px) {
    width: 100%;
  }
  .gatsby-image-wrapper {
    max-width: 60rem;
    border: ${styles.colors.primary} 2px solid;
  }
`

export default Portfolio
