import React, { useContext } from "react"
import styled from "styled-components"
import { Title, Paragraph, styles, content } from "../utils"
//import LanguageContext from "../contexts/LanguageContext"

const Contact = () => {
  //const { lang } = useContext(LanguageContext)
  return (
    <FormWrapper id="contact">
      <Title titleText="Contact" />
      <Paragraph>{content.Contact.para1.fr}</Paragraph>
      <ContactForm
        method="POST"
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <TextWrapper>
          <InputWrapper>
            <TextInput
              type="text"
              id="name"
              placeholder={"Nom"}
              name="name"
              required
            />
            <FormLabel className="form-label" htmlFor="name">
              {"Nom"}
            </FormLabel>
          </InputWrapper>
          <InputWrapper>
            <TextInput
              type="text"
              id="email"
              placeholder="Email"
              name="email"
              required
            />
            <FormLabel className="form-label" htmlFor="email">
              Email
            </FormLabel>
          </InputWrapper>
        </TextWrapper>
        <TextAreaWrapper>
          <label className="textarea-label" htmlFor="message">
            Message
          </label>
          <TextArea rows="12" id="message" name="message" />
        </TextAreaWrapper>
        <FormButton type="submit">{"Envoyer"}</FormButton>
      </ContactForm>
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  padding: 3rem 0;
  z-index: -2;
`

const FormLabel = styled.label`
  position: absolute;
  left: 1.5rem;
  top: -3rem;
  color: ${styles.colors.primary};
  font-size: 1.6rem;
  transition: all 0.6s;
  opacity: 1;
  transform: translateY(0);
`

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 6rem 0 4rem;
`

const TextWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`

const InputWrapper = styled.div`
  width: 50%;
  position: relative;
  @media (max-width: 450px) {
    width: 100%;
    margin: 3rem 0;
  }
  &:first-child {
    padding-right: 2rem;
    @media (max-width: 450px) {
      padding-right: 0;
    }
  }
  &:last-child {
    padding-left: 2rem;
    @media (max-width: 450px) {
      padding-left: 0;
    }
    & > label {
      left: 3.5rem;
      @media (max-width: 450px) {
        left: 1.5rem;
      }
    }
  }
`

const TextInput = styled.input`
  border: none;
  border-bottom: 2px solid ${styles.colors.lightGrey};
  outline: none;
  padding: 1rem 1.5rem;
  width: 100%;
  position: relative;
  font-size: 1.6rem;
  border-radius: 2px;
  font-family: "Nunito", sans-serif;
  font-weight: 300;

  &:focus {
    border-bottom: 2px solid ${styles.colors.primary};
  }
  &:placeholder-shown + .form-label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(3rem);
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 100%;
    background-color: blue;
  }
`

const TextAreaWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  .textarea-label {
    padding: 1rem 1.5rem;
    display: block;
  }
`

const TextArea = styled.textarea`
  border: 1px solid ${styles.colors.lightGrey};
  resize: none;
  outline: none;
  border-radius: 2px;
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  margin-top: 1rem;
  border-bottom: 2px solid ${styles.colors.lightGrey};
  width: 100%;
  &:focus {
    border-bottom: 2px solid ${styles.colors.primary};
  }
  & + .textarea-label {
    padding: 1rem 1.5rem;
  }
`

const FormButton = styled.button`
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  background-color: ${styles.colors.primary};
  font-family: "Nunito", sans-serif;
  color: white;
  font-weight: 100;
  margin: 4rem 0 1rem;
  max-width: 20rem;
  @media (max-width: 450px) {
    margin: 4rem auto 1rem;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    &:hover::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  }
  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transition: all 0.4s;
    background-color: ${styles.colors.primary};
  }
`

export default Contact
