import React, { useContext, useState } from "react"
import styled from "styled-components"
import { Title, Paragraph, content } from "../utils"
// import LanguageContext from "../contexts/LanguageContext"
import iconBrowser from "../images/details/browser.svg"
import iconBill from "../images/details/bill.svg"
import iconCheck from "../images/details/checklist.svg"
import iconCode from "../images/details/coding.svg"
import iconPuzzle from "../images/details/puzzle.svg"
import iconBrain from "../images/details/human-brain.svg"

const Details = () => {
  // const { lang } = useContext(LanguageContext)

  const [details] = useState([
    {
      id: 0,
      title: content.Details.first.title,
      content: content.Details.first.para,
      imgSrc: iconBrowser,
    },
    {
      id: 1,
      title: content.Details.second.title,
      content: content.Details.second.para,
      imgSrc: iconBill,
    },
    {
      id: 2,
      title: content.Details.third.title,
      content: content.Details.third.para,
      imgSrc: iconCheck,
    },
    {
      id: 3,
      title: content.Details.fourth.title,
      content: content.Details.fourth.para,
      imgSrc: iconCode,
    },
    {
      id: 4,
      title: content.Details.fifth.title,
      content: content.Details.fifth.para,
      imgSrc: iconPuzzle,
    },
    {
      id: 5,
      title: content.Details.sixth.title,
      content: content.Details.sixth.para,
      imgSrc: iconBrain,
    },
  ])

  return (
    <DetailsWrapper id="details">
      <Title titleText={content.Details.title.fr} />
      <Paragraph>{content.Details.intro.fr}</Paragraph>
      <DetailsList>
        {details.map(item => {
          return (
            <Item key={item.id}>
              <ImageWrapper>
                <img src={item.imgSrc} alt="icon" className="details-img" />
              </ImageWrapper>

              <ContentWrapper>
                <ItemTitle>{item.title.fr}</ItemTitle>
                <Paragraph>{item.content.fr}</Paragraph>
              </ContentWrapper>
            </Item>
          )
        })}
      </DetailsList>
    </DetailsWrapper>
  )
}

export default Details

const DetailsWrapper = styled.div`
  padding: 3rem 0;
`

const DetailsList = styled.ul`
  list-style: none;
  margin: 3rem auto;
`

const Item = styled.li`
  display: flex;
  margin: 1rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const ItemTitle = styled.h5`
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    margin-top: 2rem;
  }
  .details-img {
    height: 10rem;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 6;
  padding: 0.5rem 0 0.5rem 2rem;
  @media (max-width: 768px) {
    padding: 0.5rem 0 0.5rem 0;
    flex: 1;
    text-align: center;
  }
  @media (max-width: 768px) {
    p {
      text-align: center;
    }
  }
`
