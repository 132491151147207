import React from "react"
import Layout from "../components/layout"
import { Section, styles, Header } from "../utils"
import Presentation from "../components/Presentation"
import Details from "../components/Details"
import Portfolio from "../components/Portfolio"
import Technologies from "../components/Technologies"
import Contact from "../components/Contact"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Section color={styles.colors.darkGrey} pos="relative">
      <Header />
    </Section>
    <main>
      <Section>
        <Presentation />
      </Section>
      <Section>
        <Details />
      </Section>
      <Section>
        <Portfolio />
      </Section>
      <Section>
        <Technologies />
      </Section>
      <Section>
        <Contact />
      </Section>
    </main>
  </Layout>
)

export default IndexPage
