import React, { useContext } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Title, Paragraph, content } from "../utils"
//import LanguageContext from "../contexts/LanguageContext"

const Presentation = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "faceshot.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  //const { lang } = useContext(LanguageContext)

  return (
    <PresentationWrapper id="presentation">
      <LeftCol>
        <Title titleText={content.Presentation.title.fr} />
        <Subtitle>{content.Presentation.subtitle.fr}</Subtitle>
        <Paragraph>{content.Presentation.para1.fr}</Paragraph>
        <Paragraph>
          {content.Presentation.para3start.fr}
          <a href={content.Presentation.para3link.link}>
            {content.Presentation.para3link.text}
          </a>
          {content.Presentation.para3end.fr}
        </Paragraph>
        <Paragraph>
          {content.Presentation.para2start.fr}{" "}
          <a
            href={content.Presentation.para2link.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.Presentation.para2link.text}
          </a>
          {content.Presentation.para2end.fr}
        </Paragraph>
        <Paragraph>
          {content.Presentation.para4.fr}
          <a href={content.Presentation.para4link.link}>
            {content.Presentation.para4link.text.fr}
          </a>
          .
        </Paragraph>
      </LeftCol>
      <RightCol>
        <Img fluid={data.file.childImageSharp.fluid} alt="Mathias Béraud" />
      </RightCol>
    </PresentationWrapper>
  )
}

const PresentationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5rem 0 3rem;
  @media (max-width: 880px) {
    flex-direction: column;
  }
`
const RightCol = styled.div`
  order: 2;
  flex: 1;
  margin-left: 6rem;
  .gatsby-image-wrapper {
    outline: 0.5rem #26bf86 solid;
    outline-offset: 0.5rem;
  }
  @media (max-width: 880px) {
    .gatsby-image-wrapper {
      max-width: 30rem;
      margin: 4rem auto;
    }
    margin-left: 0;
  }
  @media (max-width: 450px) {
    .gatsby-image-wrapper {
      max-width: 20rem;
      margin: 4rem auto;
    }
  }
`

const LeftCol = styled.div`
  order: 1;
  flex: 2;
`

const Subtitle = styled.h3`
  font-size: 2rem;
  font-weight: 300;
  margin: 1rem 0 3rem;
`

export default Presentation
